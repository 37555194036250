import { render, staticRenderFns } from "./UserPasswordResetDialog.vue?vue&type=template&id=5f5bed6d&scoped=true"
import script from "./UserPasswordResetDialog.vue?vue&type=script&setup=true&lang=ts"
export * from "./UserPasswordResetDialog.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f5bed6d",
  null
  
)

export default component.exports